import {
    Infer,
    literal,
    nullable,
    object,
    string,
    type,
    union,
} from "superstruct";

const BrandingThemeColorSchema = union([
    literal("primaryColor"),
    literal("secondaryColor"),
]);

const BrandingFontColorSchema = union([literal("#ffffff"), literal("#1a1a1a")]);

export const BrandingOptionsSchema = object({
    login: object({
        brand: string(),
        text: string(),
        background: string(),
    }),
    reviews: object({
        reviews_main_color: string(),
        reviews_text_color: string(),
        reviews_stars_color: string(),
    }),
    rewards: object({
        rewardsPopUpWidget: object({
            theme: object({
                primaryColor: string(),
                secondaryColor: string(),
            }),
            banner: object({
                themeColor: BrandingThemeColorSchema,
                fontColor: BrandingFontColorSchema,
                image: nullable(string()),
            }),
            buttons: object({
                themeColor: BrandingThemeColorSchema,
                fontColor: BrandingFontColorSchema,
            }),
            content: object({
                iconsThemeColor: BrandingThemeColorSchema,
                linksThemeColor: BrandingThemeColorSchema,
            }),
            launcher: object({
                themeColor: BrandingThemeColorSchema,
                fontColor: BrandingFontColorSchema,
                widgetPosition: union([literal("BR"), literal("BL")]),
            }),
        }),
    }),
    wishlist: object({
        wishlist_main_color: string(),
        wishlist_text_color: string(),
        wishlist_heart_color: string(),
    }),
});

export const AdditionalInfoBrandingSchema = type({
    GW_BRANDING_OPTIONS: BrandingOptionsSchema,
});

export type AdditionalInfoBranding = Infer<typeof AdditionalInfoBrandingSchema>;
