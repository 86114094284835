import { Infer, object, string } from "superstruct";
import { InjectionToken } from "tsyringe";

export const RewardsApplicationConfigSchema = object({
    rewardsPopUpWidgetPlaceholder: string(),
    rewardsJoinNowModalWidgetPlaceholder: string(),
    rewardsVerifyAccountModalWidgetPlaceholder: string(),
});

export type RewardsApplicationConfigInterface = Infer<
    typeof RewardsApplicationConfigSchema
>;

export const RewardsApplicationConfigToken = Symbol(
    "RewardsApplicationConfigToken"
) as InjectionToken<RewardsApplicationConfigInterface>;
