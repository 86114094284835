import type AnalyticsEventsCollectorInterface from "@lib/AnalyticsEventsCollector/AnalyticsEventsCollectorInterface";
import type ApiClientInterface from "@lib/ApiClient/ApiClientInterface";
import type CurrencyProviderInterface from "@lib/CurrencyProvider/CurrencyProviderInterface";
import FetchAndXHRPatcher from "@lib/FetchAndXHRPatcher/FetchAndXHRPatcher";
import type MoneyFormatterInterface from "@lib/Formatters/MoneyFormatter/MoneyFormatterInterface";
import GrowaveFeaturesBodySchema from "@lib/GrowaveFeaturesProvider/GrowaveFeaturesBodySchema";
import { GwI18n } from "@lib/GwI18n/GwI18n";
import type HTMLSanitizerInterface from "@lib/HTMLSanitizer/HTMLSanitizerInterface";
import { LocalizationInfoProviderInterface } from "@lib/LocalizationInfoProvider/LocalizationInfoProviderInterface";
import type LocationControllerInterface from "@lib/LocationController/LocationControllerInterfce";
import type MoneyExchangerInterface from "@lib/MoneyExchanger/MoneyExchangerInterface";
import type { ReferrerProviderInterface } from "@lib/ReferrerProvider";
import ScrollManagerInterface from "@lib/ScrollManager/ScrollManagerInterface";
import type SettingsManagerInterface from "@lib/SettingsManager/SettingsManagerInterface";
import GrowaveTokenManager from "@lib/TokenManager/GrowaveTokenManager";
import type CartListenerInterface from "@modules/cart/utils/CartListener/CartListenerInterface";
import { TrackingApiService } from "@modules/tracking_events/services/TrackingApiService";
import { Infer } from "superstruct";
import type { InjectionToken } from "tsyringe";

import type GwStorefrontAppInfo from "@interfaces/GwStorefrontAppInfo";
import type { LoggerInterface } from "@interfaces/LoggerInterface";

import TranslationsApiService from "./services/TranslationsApiService/TranslationsApiService";

export const eventsCollectorToken = Symbol(
    "eventsCollectorToken"
) as InjectionToken<AnalyticsEventsCollectorInterface>;

// API Clients
export const apiClientToken = Symbol(
    "apiClientToken"
) as InjectionToken<ApiClientInterface>;

export const socialLoginApiClientToken = Symbol(
    "socialLoginApiClientToken"
) as InjectionToken<ApiClientInterface>;

export const fileUploaderAuthApiClientToken = Symbol(
    "fileUploaderAuthApiClientToken"
) as InjectionToken<ApiClientInterface>;

export const eventsCollectorAuthApiClientToken = Symbol(
    "eventsCollectorAuthApiClientToken"
) as InjectionToken<ApiClientInterface>;

export const phpAuthApiClientToken = Symbol(
    "phpAuthApiClientToken"
) as InjectionToken<ApiClientInterface>;

export const commonAuthApiClientToken = Symbol(
    "commonAuthApiClientToken"
) as InjectionToken<ApiClientInterface>;

export const translationsAuthApiClientToken = Symbol(
    "translationsAuthApiClientToken"
) as InjectionToken<ApiClientInterface>;

//Utils
export const tokenManagerToken = Symbol(
    "tokenManagerToken"
) as InjectionToken<GrowaveTokenManager>;

export const globalLoggerToken = Symbol(
    "globalLoggerToken"
) as InjectionToken<LoggerInterface>;

export const gwStorefrontAppInfoToken = Symbol(
    "authApiClientToken"
) as InjectionToken<GwStorefrontAppInfo>;

export const locationControllerToken = Symbol(
    "locationControllerToken"
) as InjectionToken<LocationControllerInterface>;

export const settingsManagerToken = Symbol(
    "settingsManagerToken"
) as InjectionToken<SettingsManagerInterface>;

export const moneyFormatterToken = Symbol(
    "moneyFormatterToken"
) as InjectionToken<MoneyFormatterInterface>;

export const moneyExchangerToken = Symbol(
    "moneyExchangerToken"
) as InjectionToken<MoneyExchangerInterface>;

export const currencyProviderToken = Symbol(
    "currencyProvider"
) as InjectionToken<CurrencyProviderInterface>;

export const cartListenerToken = Symbol(
    "cartListener"
) as InjectionToken<CartListenerInterface>;

export const referrerProviderToken = Symbol(
    "referrerProvider"
) as InjectionToken<ReferrerProviderInterface>;

export const fetchAndXhrPatcherToken = Symbol(
    "fetchAndXhrPatcher"
) as InjectionToken<FetchAndXHRPatcher>;

export const htmlSanitizerToken = Symbol(
    "htmlSanitizer"
) as InjectionToken<HTMLSanitizerInterface>;

export const gwI18nToken = Symbol("i18nToken") as InjectionToken<GwI18n>;

export const trackingApiServiceToken = Symbol(
    "trackingApiServiceToken"
) as InjectionToken<TrackingApiService>;

export const translationsApiServiceToken = Symbol(
    "translationsApiServiceToken"
) as InjectionToken<TranslationsApiService>;

export const sharedTokens = {
    globalLoggerToken,
    eventsCollectorToken,
};

export const scrollManagerToken = Symbol(
    "scrollManagerToken"
) as InjectionToken<ScrollManagerInterface>;

export const localizationInfoProviderToken = Symbol(
    "localizationInfoProviderToken"
) as InjectionToken<LocalizationInfoProviderInterface>;

export const growaveFeaturesToken = Symbol(
    "growaveFeaturesToken"
) as InjectionToken<Infer<typeof GrowaveFeaturesBodySchema>>;
